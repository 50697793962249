import { Container, Grid, Typography } from '@material-ui/core';
import { withAlertSnackBar } from '../../HOComponents/AlertSnackBarHOC';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ActivityServices } from '../../Services/activities';
import constants from '../../Utils/Constants';
import ReactPaginate from 'react-paginate';
import ApplicationCard from './ApplicationCard';
import Link from '@material-ui/core/Link';
import AlertDialog from './AlertDialog';
import { sortApplications } from '../../Utils/Comparator';
import { getCandidateHiringStage } from '../../Utils/GridOperations';
import { PhoService } from '../../Services/phoService';
import urlcat from 'urlcat';
import { getDMSFeatureFlag } from '../../Utils/DMSConstants';
import { DocumentServices } from '../../Services/documents';
import { isMENACountry } from '../../Utils/CountryUtil';
import * as CountryUtil from '../../Utils/CountryUtil';

function CandidateDashboard(props) {
  let itemsPerPage = 10;
  const history = useHistory();
  const [applications, setApplications] = useState([]);
  const [assignedPhos, setAssignedPhos] = useState([]);
  const [currentApplications, setCurrentApplications] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [isAlertActive, setIsAlertActive] = useState(false);
  const [profile, setProfile] = useState({});
  const [alert, setAlert] = useState();
  const params = useParams();
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;
  const isLaptop = width <= 960;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentApplications(applications.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(applications.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, applications]);

  async function getCombinedData() {
    await getJobProfiles();
    await getAssignedPhos();
    await getCandidateProfile();
  }

  useEffect(() => {
    getCombinedData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (applications.length > 0) {
      createDocumentAlert();
    }
    if (applications.length > 0 && assignedPhos.length > 0) {
      if (
        sessionStorage.getItem('assignedPhoJobId') !== null &&
        sessionStorage.getItem('assignedPhoJobId') !== undefined
      ) {
        redirectToPhoPortal(applications, assignedPhos);
      }
    }
    // eslint-disable-next-line
  }, [applications, assignedPhos]);

  const requestBody = {
    emailId: localStorage.getItem('emailId'),
  };

  const redirectToPhoPortal = async (applications, assignedPhos) => {
    const jobId = sessionStorage.getItem('assignedPhoJobId');
    const jobApplication = applications.find((application) => application.jobId === jobId);
    const phoApplication = assignedPhos.find((pho) => pho.jobId === jobId);
    if (applications.length !== 0 && assignedPhos.length !== 0 && jobApplication && phoApplication) {
      const createPhoData = {
        phoId: phoApplication.phoId,
        candidateId: localStorage.getItem('emailId'),
        applicationId: jobApplication.applicationId,
        accesstoken: localStorage.getItem('accesstoken'),
        idtoken: localStorage.getItem('idtoken'),
        locale: isMENACountry() ? 'en_US' : '',
      };
      let link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = urlcat(CountryUtil.getPhoBaseUrl(), '/:site/:role', {
        site: phoApplication.site,
        role: phoApplication.role,
        ...createPhoData,
      });
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
        sessionStorage.setItem('assignedPhoJobId', null);
      }, 300);
    }
  };

  const getAssignedPhos = async () => {
    await PhoService.getAssignedPhoByCandidateId({ candidateId: requestBody.emailId })
      .then((res) => {
        let assignedPhosL = (res && res.data && res.data.phoAssignments) || [];
        setAssignedPhos(
          assignedPhosL.map((pho) => {
            return {
              phoId: pho.phoId,
              jobId: pho.jobId,
              site: pho.site,
              role: pho.role,
              applicationId: pho.applicationId,
            };
          })
        );
      })
      .catch((err) => {
        props.snackbarShowMessage(
          `${constants.ERROR.PREPOPULATE_ERROR} ${err?.data?.message}`,
          'error',
          '4000',
          err?.data?.message
        );
      });
  };

  const getCandidateProfile = async () => {
    await ActivityServices.getCandidateProfileByEmail({ email: requestBody.emailId })
      .then((res) => {
        const candProfile = res && res.data;
        setProfile(candProfile);
      })
      .catch((err) => {
        props.snackbarShowMessage(
          `${constants.ERROR.PROFILE_ERROR} ${err?.data?.message}`,
          'error',
          '4000',
          err?.data?.message
        );
      });
  };

  const getJobProfiles = async () => {
    await ActivityServices.getApplicationsByCandidate(requestBody)
      .then((res) => {
        let rows = (res && res.data && res.data.applications) || [];
        let rowsData = [];
        if (rows) {
          rows.forEach((row) => {
            rowsData.push({
              jobId: row.jobId,
              jobRole: row.jobRole,
              vendorName: row.agency,
              city: row.city,
              hiringStage: getCandidateHiringStage(row.hiringStage),
              applicationId: row.applicationId,
              jobStatus: row.jobStatus,
              refId: row.refId,
              vendorId: row.vendorId,
              startDate: row.startDate,
            });
          });
        }
        rowsData = sortApplications(rowsData, constants.DASHBOARD.HIRING_STAGE);
        setApplications(rowsData);
      })
      .catch((res, error) => {
        props.snackbarShowMessage(
          `${constants.ERROR.PREPOPULATE_ERROR} ${res.data.message}`,
          'error',
          '5000',
          res.data.message
        );
      });
  };

  useEffect(() => {
    if (params && params.jobId && applications) {
      const application = applications.find((application) => application.jobId === params.jobId);
      if (
        application &&
        constants.DOCUMENTS.OFFER_LETTER_STAGES.includes(application.hiringStage) &&
        constants.JOB_STATUS.ACTIVE_STATUSES.includes(application.jobStatus)
      ) {
        history.push({
          pathname: constants.DOCUMENTS.VIEW,
          state: {
            documentType: constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage],
            application: application,
          },
        });
      } else if (application) {
        props.snackbarShowMessage(constants.ERROR.OFFER_LETTER_INVALID, 'error', '5000');
      }
    }
    // eslint-disable-next-line
  }, [applications]);

  const createDocumentAlert = () => {
    const alertContent = applications.map((application) => {
      if (
        constants.DOCUMENTS.ALERT_STATUSES.includes(application.hiringStage) &&
        !constants.JOB_STATUS.DISCONTINUE_STATUSES.includes(application.jobStatus)
      ) {
        setIsAlertActive(true);
        return createAlertLink(application);
      }
      return '';
    });
    setAlert(alertContent);
  };

  const redirectToPreviewPage = async (application) => {
    if (getDMSFeatureFlag()) {
      let docMapDict = {};
      let body = {
        candidateIds: [application.applicationId],
        userType: 'CANDIDATE',
      };
      let candidateDocuments = await DocumentServices.getDocuments(body);
      candidateDocuments.data.documents.forEach((doc) => {
        docMapDict[constants.DOCUMENTS.DMS_DOCUMENT_TYPE_MAP[doc.documentType]] = doc;
      });
      let docType = constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage];
      history.push({
        pathname: constants.DOCUMENTS.VIEW,
        state: {
          documentType: docType,
          application: application,
          documentData: docMapDict[docType],
        },
      });
      return;
    }
    history.push({
      pathname: constants.DOCUMENTS.VIEW,
      state: {
        documentType: constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage],
        application: application,
      },
    });
  };

  const createAlertLink = (application) => {
    return (
      <li>
        {`${application.jobId} - `}
        <Link
          sx={{ paddingLeft: '10px' }}
          className="primary_color"
          onClick={async () => {
            await redirectToPreviewPage(application);
          }}
        >
          {`${constants.DOCUMENTS.HIRING_STAGE_DOCUMENT_TYPE_MAP[application.hiringStage]}`}
        </Link>
      </li>
    );
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % applications.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <Container maxWidth="lg" style={{ padding: '16px' }}>
        <Grid container id="application-container" direction="column" spacing={isMobile ? 2 : 6}>
          <Grid xs item>
            <Typography variant="h3">Applications</Typography>
          </Grid>
          {isAlertActive && !isMENACountry() && <AlertDialog isMobile={isMobile} alert={alert} />}

          <Grid container item spacing={6} direction={isLaptop ? 'column-reverse' : 'row'}>
            <Grid container item xs direction="column">
              {currentApplications &&
                currentApplications.map((application) => (
                  <Grid item xs key={application.applicationId}>
                    <ApplicationCard
                      isMobile={isMobile}
                      application={application}
                      profile={profile}
                      pho={assignedPhos && assignedPhos.find((pho) => pho.applicationId === application.applicationId)}
                      id={application.applicationId}
                    />
                  </Grid>
                ))}
              <Grid container item alignItems="center" justifyContent="center">
                <ReactPaginate
                  containerClassName={'reactPaginate'}
                  activeClassName={'selectedPage'}
                  previousClassName={'previousPage'}
                  nextClassName={'nextPage'}
                  disabledClassName={'disableButton'}
                  breakClassName={'paginationBreak'}
                  breakLabel="..."
                  nextLabel=" >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md="auto">
              <Typography style={{ maxWidth: isLaptop ? 'none' : '328px', color: '#56616C' }}>
                {constants.ALERTS.STAFFING_AGENCY_INFORMATION}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default withAlertSnackBar(CandidateDashboard);
